import React, { useContext } from 'react'
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames'
// ui-kit
import {
    Container,
    Button,
    Title,
    Row
} from '../../../ui-kit'
// styles
import s from './FirstScreen.module.sass'
// svg
import Circles from '../../../icons/blog-inner/circles.svg'
// context
import {LayoutContext} from '../../Layouts/layout'

export default function FirstScreen({
  title,
  image,
  content
}) {
    const {local: {langBase, YAMLGlobal}} = useContext(LayoutContext);
    
    return (
        <section className={s.firstScreen}>
            <Container>
                <Title className={s.desktop}>{title}</Title>
                <div className={s.wrapper}>
                    <div className={s.img}>
                        <div className={s.square} />
                        <div className={s.circles}><Circles/></div>
                        <div className={s.inner}>
                            <Img fluid={image} className={s.gatsby} />
                        </div>
                    </div>
                    <Title className={s.mobile}>{title}</Title>
                    <div className={s.content} dangerouslySetInnerHTML={{__html: content}} />
                </div>
                <div className={s.clear}></div>
                <Row justify="justify-content-center">
                    <Link to={`${langBase}/blog`} className="link"><Button className={classNames("green", s.button)}>{YAMLGlobal.button.allArticles}</Button></Link>
                </Row>
            </Container>
        </section>
    )
}