import React from "react"
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
// components
import {
    FirstScreen
} from '../components/BlogInner'
// global
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"
import { graphql } from "gatsby"

const BlogInner = ({ data, location }) => {
    const post = data.allDirectusBlog.edges[0].node
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/blog',
            title: YAMLGlobal.menu.blog
        },
        {
            link: `/blog/${post.slug}`,
            title: post.title_ua
        }
    ]

    const blogInnerLocal = {
        langBase: "/ua",
        YAMLGlobal,
    }
    
    return(
        <Layout local={blogInnerLocal} location={location}>
            <SEO
              title={post.title_ua}
              description={post.description_ua}
              image={post.image.localFile?.childImageSharp.fluid.src}
              lang='ua'
            />
            <Breadcrumbs list={list} />
            <FirstScreen
              title={post.title_ua}
              content={post.content_ua}
              image={post.image.localFile?.childImageSharp.fluid}
            />
            <FormOrder wave="white" />
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        allDirectusBlog (filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    slug
                    title_ua
                    description_ua
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1200) {
                                    src
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    content_ua
                }
            }
        }
    }
`

export default BlogInner;